import "./style.scss";
import jQuery from "jquery";
import "magnific-popup";
import "magnific-popup/dist/magnific-popup.css";
import icon from "./img/icon.xml";
import Cookie from "js-cookie";

var $ = (window.$ = jQuery);

class PopupAnnouncement {
  constructor(args) {
    this.title = args.title;
    this.contentType = args.contentType;
    this.content = args.content;
    this.toggleLabel = args.toggleLabel;
    this.cookie = args.cookie || false;
  }

  setCookie() {
    if (!!this.cookie) {
      Cookie.set('__pa', this.cookie)
    }
  }

  getTemplate() {
    return `<div class="popup-announcement__board">
  <h6 class="popup-announcement__title">${this.title}</h6>
  <div class="popup-announcement__content">${this.content}</div>
</div>`;
  }

  buttonToggle() {
    return `<div class="popup-announcement-toggle">
      <button class="popup-announcement-toggle__button">
        <span class="popup-announcement-toggle__icon">${icon}</span>
        <span class="popup-announcement-toggle__label">${this.toggleLabel}</span>
      </button>
    </div>`
  }

  init() {
    var _this = this;

    var openPopup = () => {
      return $.magnificPopup.open({
        items: {
          type: _this.contentType,
          src: _this.getTemplate()
        },
        mainClass: "popup-announcement",
        removalDelay: 150,
        callbacks: {
          close() {
            appendButton()
            
            if (!!_this.cookie) {
              Cookie.set('__pa', _this.cookie)
            }
          }
        }
      });
    }

    var $btn = $(_this.buttonToggle());
    $btn.on('click', () => openPopup())

    var appendButton = () => {
      if (!$('body').find($btn).length) {
        $('body').append( $btn );
      }
    }

    if (!!this.cookie) {
      if ( Cookie.get('__pa') !== this.cookie ) {
        openPopup()
      } else {
        appendButton()
      }
    } else {
      openPopup()
    }
  }
}

export {PopupAnnouncement};